<template>
  <a-modal
    :title="title"
    width="1000px"
    :visible="visible"
    @cancel="handleCancel"
    :maskClosable="false"
    :keyboard="false"
    :footer="null"
    class="train-user-dialog"
  >
    <div class="dialog-content">
      <div class="search-box">
        <a-form-model ref="form" :model="form" class="search_form page-form-search" labelAlign="left" layout="inline">
          <a-form-model-item label="姓名">
            <a-input v-model="form.realName" placeholder="请输入姓名" allowClear style="width: 180px" />
          </a-form-model-item>
        </a-form-model>
        <div class="form_btn_box" v-perms:pxqk="'search'">
          <a-button type @click="handleReset">重置</a-button>
          <a-button type="primary" style="margin-left: 12px" @click="handleSearch">搜索</a-button>
        </div>
      </div>
      <div class="table-box">
        <div class="export-btn" style="margin-bottom: 12px; text-align: right;">
          <a-button type="primary" @click="handleExport" :disabled="$refs.table && !$refs.table.tableData.length" v-perms:pxqk="'download'">导出数据</a-button>
        </div>
        <p-table ref="table" row-key="id" :columns="columns" :sourceData="getDataApi" :scorll="{ y: 500 }"> </p-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getTrainPlanUser, exportTrainPlanUser } from '@/api/train'
import moment from 'moment'

export default {
  name: 'TRAINUSER',
  components: {},
  props: {},
  data () {
    return {
      form: {},
      visible: false,
      planId: null,
      statusList: [
        { value: 0, label: '未完成' },
        { value: 1, label: '合格' },
        { value: 2, label: '不合格' }
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '培训计划名称',
          dataIndex: 'trainingPlanName',
          key: 'trainingPlanName',
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          key: 'realName'
        },
        {
          title: '培训学时',
          dataIndex: 'duration',
          key: 'duration'
        },
        {
          title: '考试分数',
          dataIndex: 'score',
          key: 'score'
        },
        {
          title: '考试完成时间',
          dataIndex: 'submitTime',
          key: 'submitTime',
          customRender: (t) => t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        {
          title: '考试结果',
          dataIndex: 'status',
          key: 'status',
          customRender: (t) => {
            const data = this.statusList.find((item) => item.value == t) || {}
            return data.label
          }
        }
      ]
    }
  },
  computed: {
    title () {
      return `培训情况`
    },
    selectRowIds () {
      return this.selectData.map((item) => item.id)
    }
  },
  methods: {
    handleSearch () {
      this.$refs.table.initPage()
    },
    handleReset () {
      this.$set(this, 'form', {})
    },
    getDataApi (params) {
      if (this.planId) {
        return getTrainPlanUser({
          ...params,
          ...this.form,
          trainingPlanId: this.planId
        })
      } else {
        return Promise.resolve({})
      }
    },
    createModel (rowItem) {
      this.planId = rowItem.id
      this.visible = true
      this.$nextTick(() => {
        this.$refs.table.initPage()
      })
    },
    handleCancel () {
      this.visible = false
      this.planId = null
    },
    handleExport () {
      this.$spin.show()
      exportTrainPlanUser({
        ...this.form,
        enterpriseId: this.$store.getters.enterpriseId,
        trainingPlanId: this.planId
      })
        .then((response) => {
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          const fileName = '培训情况数据'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const a = document.createElement('a')
            const URL = window.URL || window.webkitURL
            const href = URL.createObjectURL(blob)
            a.href = href
            a.download = fileName
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(href)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          this.$spin.hide()
        })
    }
  }
}
</script>

<style lang="less">
.train-user-dialog {
  .dialog-content {
    padding-bottom: 50px;
    .search-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
  }
}
</style>
