import request from '@/utils/request'
import qs from 'qs'

// 新增培训权限审核
export function addTrainPerms (data) {
  return request({
    url: '/api/enterprise/employmentTraining/audit',
    method: 'post',
    data
  })
}

// 分页查询培训权限审核
export function getTrainPerms (params) {
  return request({
    url: '/api/enterprise/employmentTraining/audit/list',
    method: 'get',
    params
  })
}

// 修改用户培训权限审核
export const updateUserTrainPerms = (params) => {
  return request
    .put('/api/enterprise/employmentTraining/userAudit', null, {
      params: {
        ...params
      },
      paramsSerializer: {
        serialize: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    })
    .then((response) => response)
}

// 分页查询用户培训权限审核
export function getUserTrainPerms (params) {
  return request({
    url: '/api/enterprise/employmentTraining/userAudit/page',
    method: 'get',
    params
  })
}

// 删除用户培训权限审核
export function deleteUserTrainPerms (params) {
  return request({
    url: '/api/enterprise/employmentTraining/userAudit',
    method: 'delete',
    params
  })
}

// 批量删除用户培训权限审核
export const deleteAllUserTrainPerms = (data) => {
  return request({
    url: '/api/enterprise/employmentTraining/userAudit/batch',
    method: 'delete',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 新增培训计划
export function addTrainPlan (data) {
  return request({
    url: '/api/enterprise/employmentTraining/trainingPlan',
    method: 'post',
    data
  })
}

// 查询培训计划详情
export function lookTrainPlan (id) {
  return request({
    url: `/api/enterprise/employmentTraining/trainingPlan/${id}`,
    method: 'get'
  })
}

// 批量删除培训计划
export const deleteAllTrainPlan = (data) => {
  return request({
    url: '/api/enterprise/employmentTraining/trainingPlan/batch',
    method: 'delete',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 分页查询培训计划
export function getTrainPlanData (params) {
  return request({
    url: '/api/enterprise/employmentTraining/trainingPlan/page',
    method: 'get',
    params,
    paramsSerializer: {
      serialize: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  })
}

// 分页查询培训情况
export function getTrainPlanUser (params) {
  return request({
    url: '/api/enterprise/employmentTraining/trainingPlanUser/page',
    method: 'get',
    params
  })
}

// 导出培训情况数据
export function exportTrainPlanUser (params) {
  return request.get(
    '/api/enterprise/employmentTraining/trainingPlanUser/download',
    {
      params,
      responseType: 'blob'
    }
  )
}

// 查询所有工种专业类别
export const getAllcateType = () => {
  return request
    .get(`/api/back/employmentTraining/jobType/queryAllPosition`)
    .then((response) => response)
}

// 分页查询培训资料
export const getDataByPage = (params) => {
  return request
    .get('/api/enterprise/employmentTraining/material/page', {
      params: {
        ...params
      },
      paramsSerializer: {
        serialize: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    })
    .then((response) => response)
}

// 分页查询培训资料
export const getDataAllCategory = (params) => {
  return request
    .get('/api/enterprise/employmentTraining/question/findCategory', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 根据试题类型查询题目数量
export const getSTNum = (data) => {
  return request({
    url: '/api/enterprise/employmentTraining/question/groupByType',
    method: 'post',
    data
  })
}

// 培训统计
export const getTrainStatic = (params) => {
  return request
    .get('/api/enterprise/employmentTraining/trainingPlanUser/statistics', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 培训统计详情导出
export const exportTrainStatic = (params) => {
  return request
    .get('/api/enterprise/employmentTraining/trainingPlanUser/statisticsInfo/download', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 查询视频/微课分类
export const getTreeByType = params => {
  return request.get('/api/plat/employmentLearn/platCategoryController/findTreeByType', {
    params
  }).then(response => response)
}
